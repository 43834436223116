
/* You can add global styles to this file, and also import other style files */
@import 'material-icons/iconfont/material-icons.css';
html {
  font-size: 12px;
}
html, body { height: 100%; }
body { margin: 0; font-family: Roboto, "Helvetica Neue", sans-serif; }

.main-card {
  padding: 10px;
}

.flex-container {
display: flex;
flex-direction: row;
justify-content: center;
align-items: center;
}

// <uniquifier>: Use a unique and descriptive class name
// <weight>: Use a value from 100 to 900

@font-face {
  font-family: "Geologica";
  src: url("assets/fonts/geologica.ttf") format("woff2");

}

.geologica-benington {
  font-family: "Geologica", sans-serif;
  font-optical-sizing: auto;
  font-weight: 400;
  font-style: normal;
  font-variation-settings:
    "slnt" 0,
    "CRSV" 0,
    "SHRP" 0;
}